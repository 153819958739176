import TYPES from '@/types';

// Application
import {
  SearchAllianzAccountStatementsQuery,
  DownloadAllianzAccountStatementQuery,
} from '@/modules/my-investment/allianz/allianz-account-statement/application/queries';

// Domain
import {
  AllianzAccountStatementEntity,
} from '@/modules/my-investment/allianz/allianz-account-statement/domain/entities/allianz-account-statement-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AccountStatementsDialogViewModel {
  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_STATEMENTS_QUERY)
  private readonly search_allianz_account_statements!: SearchAllianzAccountStatementsQuery;

  @Inject(TYPES.DOWNLOAD_ALLIANZ_ACCOUNT_STATEMENT_QUERY)
  private readonly download_allianz_account_statement!: DownloadAllianzAccountStatementQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.account-statements';

  private readonly required_files = [
    'allianz_account_status',
  ];

  account_statements: Array<AllianzAccountStatementEntity> = [];

  is_loading = false;

  show_document_dialog = false;

  file_to_show = {
    quarter: '',
    file_name: '',
    file_content: '',
    mime_type: '',
  }

  are_files_loading = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadAllianzAccountStatements = async () => {
    try {
      this.account_statements = await this.search_allianz_account_statements.execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_documents'));
    }
  }

  loadFile = async (account_statement: AllianzAccountStatementEntity) => {
    try {
      const download_account_statement = {
        file_path: account_statement.file_path,
        file_name: account_statement.file_name,
        mime_type: account_statement.mime_type,
      };
      const downloaded_account_statement = await
      this.download_allianz_account_statement.execute(download_account_statement);
      this.file_to_show = { quarter: account_statement.quarter, ...downloaded_account_statement };
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_file'));
    }
  }

  selectAccountStatement = async (account_statement: AllianzAccountStatementEntity) => {
    this.is_loading = true;
    await this.loadFile(account_statement);
    this.show_document_dialog = true;
    this.is_loading = false;
  }

  initialize = async () => {
    this.are_files_loading = true;
    await this.loadAllianzAccountStatements();
    this.are_files_loading = false;
  }
}
