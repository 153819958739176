





















































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import AccountStatementsDialogViewModel
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/account-statements-dialog-view-model';

@Component({
  name: 'AccountStatementsDialog',
  components: {
    DocumentDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/DocumentDialog.vue'),
  },
})
export default class AccountStatementsDialog extends Vue {
  @PropSync('isOpen', {
    type: Boolean,
    required: true,
    default: false,
  })
  synced_is_open!: boolean;

  account_statements_view_model = Vue.observable(new AccountStatementsDialogViewModel());

  created() {
    this.account_statements_view_model.initialize();
  }

  closeDialog() {
    this.synced_is_open = false;
  }
}
